import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import { Redirect } from 'react-router';
import axios from 'axios';
import * as base from "../global";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Row.css';
//https://reactjsexample.com/a-lightweight-production-ready-carousel-for-react/

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};



export class dramaseries extends Component {
    constructor() {
        super();
        let loggdin = false;
        this.state = {
            allsubject: []
        }
    }
    componentDidMount() {
        axios.get(base.BASE_URL + '/dramalist').then(res => {
            this.setState({ allsubject: res.data });
        });
    }


    render() {
        /*     if (this.state.loggdin) {
              return <Redirect to="/Dashboard" />
            } */
        return (
            <div className="row">
                {/*                 <div className="favorites-contens">

                <Carousel responsive={responsive}>
                         {this.state.allsubject.map((result) => {
                            return (
 
                                <div className="slide-item" style={{ marginRight: 10 }}>
                                <div className="block-images position-relative">
                                  <div className="img-box">
                                  <Link to={`/moviedetails/${result.movieid}/${result.categoryid}/${result.moviename }`}>
                                        <img src={`${base.IMAGE_URL}/${result.image}`} className="img-fluid img-zoom"
                                          alt={result.moviename} style={{ height: 202, width: '100%' }} />
                                      </Link>
                                  </div>
                                  <div className="block-description">
                                  </div>

                                </div>
                              </div>
                             
                         )
                        })} 
                        </Carousel>

                </div> */}
                <div className="row__posters">
                    {this.state.allsubject.map((result) => {
                        return (
                         /*    <Link to={`/moviedetails/${result.movieid}/${result.categoryid}/${result.moviename}`}> */
                                <img src={`${base.IMAGE_URL}/${result.image}`}
                                    className="row__poster"
                                    key={result.movieid}
                                    alt={result.moviename} />
                          /*   </Link> */

                        )
                    })}
                </div>

            </div>
        )
    }
}

export default dramaseries
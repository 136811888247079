import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
//import useForm from 'react-hook-form'
import { Button, Form } from 'reactstrap';
import * as base from "./global";
import axios from 'axios';
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
//https://stackoverflow.com/questions/41296668/reactjs-form-input-validation
import ActivityIndicator from 'react-activity-indicator'
//npm install react-activity-indicator
import 'react-activity-indicator/src/activityindicator.css'


export class Login extends Component {

  constructor() {
    super();
    this.register = this.register.bind(this);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      Password: '',
      isLoading: false,
      loaderfile:'notloading',
      fields: {},
      errors: {},
      loggdin: 'stop',
      counter:0
    }


  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //  alert(this.state.fields["email"]);
    //Name
    if (!this.state.fields["password"]) {
      formIsValid = false;
      errors["password"] = "Cannot be empty";
    }


    /*     if (typeof this.state.fields["email"] !== "undefined") {
          if (!this.state.email.match(/^[a-zA-Z]+$/)) {
            formIsValid = false;
            errors["email"] = "Only letters";
          }
        } */

    //Email
    if (!this.state.fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }

    if (typeof this.state.fields["email"] !== "undefined") {
      let lastAtPos = this.state.fields["email"].lastIndexOf('@');
      let lastDotPos = this.state.fields["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (this.state.fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  register() {
    if (this.handleValidation()) {
      //  alert("Form submitted");
      this.setState({ loaderfile: 'loading' })
      fetch(base.BASE_URL + '/vendorlogin', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.state.fields["email"],
          password: this.state.fields["password"]
        })
      }).then((Response) => Response.json())
        .then((Result) => {
          //   alert(Result.message);\
          if (Result.message == 'success') {
            localStorage.setItem("token", 'dsfsdfdsfdsf314654654654654165464')
            localStorage.setItem("vendoremailaddress", this.state.fields["email"])

            this.setState({ loggdin: 'start', loaderfile: 'notloading' })

          }
          else {
            this.setState({ loaderfile: 'notloading' })
            alert('Sorrrrrry !!!! Un-authenticated User !!!!!')
          }

        })
      /////////////////////////////////////////////////////////////////////
    } else {
      // alert("Form has errors.")
    }


  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    // alert(this.state.loggdin);
    if (this.state.loggdin == 'start') {
      return <Redirect to="/Dashboard" />
    }
    return (
      <div>
        <Header />
        <section className="sign-in-page">
          <div className="container">
            <div className="row justify-content-center align-items-center height-self-center">
              <div className="col-lg-7 col-md-12 align-self-center">
                <div className="sign-user_card ">
                  <div className="sign-in-page-data">
                    <div className="sign-in-from w-100 m-auto">
                    {
                        this.state.loaderfile == 'notloading'
                        ?

                      <Form className="lg:p-6 p-6 space-y-3 relative shadow-xl rounded-md" >
                        <h1 className="lg:text-2xl text-xl font-semibold mb-6" style={{ fontSize: 22, marginBottom: 9 }}> Vendor Sign In </h1>
                        <div>
                          <label className="mb-0"> Email Address </label>
                          <input type="email" placeholder=" " name="email" /* value={this.state.email}  onChange={this.email} */
                            onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}
                            className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" />
                          <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                        </div>
                        <div>
                          <label className="mb-0"> Password </label>
                          <input type="password" placeholder=" " name="password" /* value={this.state.password} onChange={this.password} */
                            onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]}
                            className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" />
                          <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                        </div>
                        <div>
                          <Button type="button" className="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full" onClick={this.register} style={{ marginTop: -10 }}>
                            Login </Button>
                        </div>
                      </Form>
                       :
                       <div>
                     <ActivityIndicator number={3} duration={200} activeColor="#0070bf" borderWidth={2} borderRadius="50%" diameter={25} />
                     {/*   <ActivityIndicator number={10} duration={200} activeColor="#0070bf" borderWidth={2} borderRadius="50%" diameter={25} /> */}
                       </div>
                       }
 

                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex justify-content-center links">
                      Create New Account ? <Link to="/Continuewithemail" className="text-primary ml-2">Registration </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>


    )

  }
}

export default Login
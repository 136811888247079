import React, { useEffect, useState } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
//import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../Layout/Footer';


import Dramaseries from './drama/dramaseries'
import Movielist from './drama/movielist'
import Episodelist from './drama/Episodelist'
import Musiclist from './drama/musiclist'


export default function Dashboard(props) {
  const [moviedetails, setMoviedetails] = useState([]);


  useEffect(() => {
    // alert(userid);
    const getConversations = async () => {
      try {
        const res = await axios.get(base.BASE_URL + "/allvideo.php");
        setMoviedetails(res.data);
        //console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, []);



  return (
    <div>
    
      <div className="main-content" style={{ marginTop: 0 }}>

      <Header />
<section id="iq-favorites">
  <div className="container-fluid" >
    <div className="row">
      <div className="col-sm-12 overflow-hidden">
        <div className="iq-main-header d-flex align-items-center justify-content-between" style={{ marginTop: 90 }}>
          <h4 className="main-title">Dashboard</h4>
        
        </div>

         {/* Episode*/}
            <Episodelist />

        {/* End Drama Series */}
      </div>
    </div>
  </div>
</section>




</div>     
<Footer/>
    </div>
  );

}

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import * as base from "./global";
import { Redirect } from 'react-router';
import axios from 'axios';
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Sliderpage from './slider/sliderpage'
import Dramaseries from './drama/dramaseries'
import Movielist from './drama/movielist'
import Episodelist from './drama/Episodelist'
import Musiclist from './drama/musiclist'

export class Home extends Component {
  constructor() {
    super();
    let loggdin = false;
    this.state = {
      email: '',
      Password: '',
      isLoading: false,
      fields: {},
      errors: {},
      datauserprofile: [],
      image: 'maleavter.png'
    }
  }
  componentDidMount() {
    const formdata = {
      pagetype: "Homepage"
      }
      axios.post(base.BASE_URL + "/visitor", formdata, {
      }).then(res => {
      console.log('data..file..' + res.data);
      })

  }

  render() {
/*     if (this.state.loggdin) {
      return <Redirect to="/Dashboard" />
    } */
    return (
      /*     <div style={{ backgroundImage: `url(${background})` }}> profile-cover main-banner.jpg*/


      <div>
          <Header/>

        <Sliderpage/>

<div className="main-content" style={{ marginTop: 0 }}>

<section id="iq-favorites" style={{ marginTop: 10 }}>
{/*     <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 overflow-hidden">
          <div className="iq-main-header d-flex align-items-center justify-content-between">
            <h4 className="main-title">DRAMA</h4>
            <Link to="/Drama" className="main-title">View More</Link>
          </div> */}
              <Dramaseries title="Drama" />
{/*         </div>
      </div>
    </div> */}
  </section>

<section id="iq-favorites" style={{ marginTop: 19 }}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 overflow-hidden">
          <div className="iq-main-header d-flex align-items-center justify-content-between">
            <h4 className="main-title">MUSIC</h4>
            <a href="show-single.html" className="main-title">View More</a>
          </div>
              <Musiclist />
        </div>
      </div>
    </div>
  </section>




{/*   <section id="iq-favorites">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 overflow-hidden">
          <div className="iq-main-header d-flex align-items-center justify-content-between">
            <h4 className="main-title">EPISODE</h4>
            <Link to="/Drama" className="main-title">View More</Link>
          </div>
              <Episodelist />
        </div>
      </div>
    </div>
  </section> */}

{/*   <section id="iq-favorites" style={{ marginTop: 19 }}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 overflow-hidden">
          <div className="iq-main-header d-flex align-items-center justify-content-between">
            <h4 className="main-title">MOVIE</h4>
            <Link to="/Movies" className="main-title">View More</Link>
          </div>
              <Movielist />
        </div>
      </div>
    </div>
  </section> */}


{/* Music */}




</div>

<Footer/>
      </div>
    )
  }
}

export default Home
import React, { useEffect, useState } from 'react';
//import { Redirect } from 'react-router';
//import Header from '../Layout/Header'
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
//import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//npm i --save-dev @types/react-jw-player
//https://www.npmjs.com/package/react-jw-player
//https://www.npmjs.com/package/react-jw-player
import ReactJWPlayer from 'react-jw-player';


function Ads(props) {




    return (
       <div>
            <Header />
            <section id="iq-favorites" >
                <div className="container-fluid">
                    <div className="block-space">

                        <div className="row" style={{ marginTop: 40 }}>
                            <div className="col-md-8">
                                    <div>
                                        <div className="col-md-12">
                                            <ReactJWPlayer
                                                playerId="jw-player"
                                                playerScript="https://content.jwplatform.com/libraries/jvJ1Gu3c.js"
                                              //  playlist="https://cdn.jwplayer.com/v2/media/gaCRFWjn"
                                            //  playlist="https://cdn.jwplayer.com/v2/media/qyGCW9Em" our
                                                playlist="https://cdn.jwplayer.com/v2/media/x1IllwJN"
                                                onDisplayClick={() => (document.body.style.backgroundColor = "black")}
                                            />
                                        </div>
                                        <div className="col-md-12">

                                        </div>
                                    </div>


                            </div>
                            <div className="col-md-4">

                            </div>
        
                      </div>
                    </div>
                </div>
            </section>



        </div>
    );

}
export default Ads;
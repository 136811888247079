import React, { Component } from 'react'

export const BASE_URL = "https://hussklive.com/api/public/api";
export const IMAGE_URL = "https://hussklive.com/cms/assets/uploads"; 


/* export const BASE_URL = "http://192.168.100.27/OTTJibondhara_git/OTTPlatform/laravel_api/public/api";
export const IMAGE_URL = "http://192.168.100.27/OTTJibondhara_git/OTTPlatform/OTTAdminpanel/assets/uploads";  */
/* 
export const BASE_URL = "http://192.168.100.6/OTTPlatform/laravel_api/public/api";
export const IMAGE_URL = "http://192.168.100.6/OTTPlatform/OTTPlatform_admin/admin/assets/uploads"; 

*/

//http://hussklive.com/api/public/api/

/* 

export const BASE_URL = "http://192.168.100.10/OTTPlatform/laravel_api/public/api";
//export const IMAGE_URL = "http://192.168.100.10/OTTPlatform/OTTPlatform_admin/assets/uploads"; 
//export const BASE_URL = "https://itechsoftbd.com/datingapp_api/public/api";  




/*
export default {
    STORE_KEY: 'a56z0fzrNpl^2',
    BASE_URL: 'http://192.168.0.103/Aced_mobileapp',
    COLOR: {
      ORANGE: '#C50',
      DARKBLUE: '#0F3274',
      LIGHTBLUE: '#6EA8DA',
      DARKGRAY: '#999',
    },
  };

*/
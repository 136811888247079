import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import { Redirect } from 'react-router';
import axios from 'axios';
import * as base from "../global";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//https://reactjsexample.com/a-lightweight-production-ready-carousel-for-react/


export class Episodelist extends Component {
    constructor() {
        super();
        let loggdin = false;
        this.state = {
            allsubject: []
        }
    }
    componentDidMount() {
        axios.get(base.BASE_URL + '/visitorcountdetails').then(res => {
            this.setState({ allsubject: res.data });
        });
    }


    render() {
        /*     if (this.state.loggdin) {
              return <Redirect to="/Dashboard" />
            } */
        return (
            /*     <div style={{ backgroundImage: `url(${background})` }}> profile-cover main-banner.jpg*/
            <div style={{ marginRight: 10, flexDirection: 'row' }}>
                {/*  <div className="favorites-contens"> */}

                {this.state.allsubject.map((result) => {
                    return (

                        <div className="col-md-4" style={{
                            marginRight: 10, borderWidth: 1,
                            borderColor: '#393750',
                            height: 100,marginBottom: 50
                        }}>
                            <div className="sign-user_card text-center" style={{ marginBottom: 20 }}>
                                <h3>Visitor</h3>
                                {result.total}
                            </div>
                        </div>

                    )
                })}


                {/*    </div> */}
            </div>
        )
    }
}

export default Episodelist